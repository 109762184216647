//返回星座
export function Constellation(str:string):string{ //星座  接受 Data 返回的日期
  let arr = str.split("-"),
      num = Number(`${arr[1]}.${(Number(arr[2]) > 9) ? arr[2] : "0"+arr[2]}`),
      Str = "";
    if(num >= 3.21 && num <= 4.19){
        Str = "白羊座"
    }else if(num >= 4.20 && num <= 5.20){
        Str = "金牛座"
    }else if(num >= 5.21 && num <= 6.21){
        Str = "双子座"
    }else if(num >= 6.22 && num <= 7.22){
        Str = "巨蟹座"
    }else if(num >= 7.23 && num <= 8.22){
        Str = "狮子座"
    }else if(num >= 8.23 && num <= 9.22){
        Str = "处女座"
    }else if(num >= 9.23 && num <= 10.23){
        Str = "天平座"
    }else if(num >= 10.24 && num <= 11.22){
        Str = "天蝎座"
    }else if(num >= 11.23 && num <= 12.21){
        Str = "射手座"
    }else if(num >= 12.22 && num <= 1.19){
        Str = "魔蝎座"
    }else if(num >= 1.20 && num <= 2.18){
        Str = "水瓶座"
    }else if(num >= 2.19 && num <= 3.20){
        Str = "双鱼座"
    }else{
    Str = "啥也不是"
    }
  return Str;
}

export function FilterAppointList ():Array<{ title:string;value:number }>{
    // 10:15 23:59
    let shi = 10,
        fen = 0,
        AppointList = [],
        num = 1,
        sepTime = 30; // 间隔15分钟
    let date = new Date(),
        Shi = date.getHours(),
        Fen = date.getMinutes(),
        Last = Math.floor( Fen / sepTime ); // 这是求出现在 时间的 展示下一个时间段
    if ( Last > 3 ){
        Shi += 1;
    }
    if ( Shi >= 23 && Fen >= 45 ){
        AppointList.push({
            title:"隔天送达",
            value:0
        })
        return AppointList;
    }
    if ( Shi < shi ){
        Shi = shi
    }
    fen = (Last + 1) * sepTime
    for ( let i = Shi; i <= 23; i ++  ){
        for ( let j = fen; j <= 60;j+=sepTime ){
            let f = ( j + sepTime )
            let val = "";
            if ( i >= 23 ){
                if ( f >= 50 ){
                    val = `23:59`
                }else{
                    val = `${ i }:${ f > 9 ? f : '0'+f }`
                }
            }else{
                if ( f >= 50 ){
                    val = `${ i + 1 }:00`
                }else{
                    val = `${ i }:${ f > 9 ? f : '0'+f }`
                }
            }
            let sh:string|number = "";
            let sf_:string|number = ""
            if ( j >= 50 ){
                sh = i + 1;
                sf_ = `${ sh }:00`
                fen = 0;
            }else{
                sf_ = `${ i }:${ j > 9 ? j : '0'+j }`
            }

            if ( !(sf_ === val ) ){
                AppointList.push({
                    title:`${ sf_ } - ${ val }`,
                    value:num
                })
                num += 1;
                if ( num === 1 ){
                    fen = 0;
                }
                if ( val === `23:59` )break;
            }

        }
    }
    return AppointList;
}

/**
 *  订单详情计算 送餐时间
*/
export function handleAppointTime(hours:number,fen:number):string|number[]{
    let Fen = fen + 30;
    let Shi = hours
    if( Fen > 59 ){
        Shi += 1
        Fen = Fen % 60
    }
    return [ Shi,Fen, ]
}

export function handleFilterStrTime(num:number):string{
    if( num < 10 ){
        return "0" + num
    }else{
        return num + ""
    }
}

export function handleComputedTime( AppointList:Array<number>,TimeList:Array<number> ):string{
    let Num = Number( AppointList[0] + "" + AppointList[1] ) - Number( TimeList[0] + "" + TimeList[1] )
    if( Num > 0 ){
        return `预计${AppointList[0]}:${ handleFilterStrTime(AppointList[1])}(${Num}分钟之后)`
    }else{
        return `预计${AppointList[0]}:${ handleFilterStrTime(AppointList[1])}(超时${ ( Math.abs(Num) > 59 ) ? Math.abs(Num)+'分钟' : Math.floor( Math.abs(Num) / 2 ) + '小时' + Math.abs( Math.abs(Num) % 2 ) + "分钟"})`
    }
}

export function handleFilterDate( timeStr:string ):string{//2021-01-22 07:56:18
    let date = new Date()
    let year = date.getFullYear()
    let moth = date.getMonth() + 1
    let day = date.getDate()
    let Hours = date.getHours()
    let Fen = date.getMinutes()
    let dateTime:any ;
    let MothTime:any ;
    let HoursTime:any ;
    let AppointTime:any ;
    try{
        dateTime = timeStr.split(" ")
        MothTime = dateTime[0].split("-")
        HoursTime = dateTime[1].split(":")
        AppointTime = handleAppointTime( Number( HoursTime[0]),Number(HoursTime[1]) ) 
    }catch(cat){
        //
    }
    if( year == Number( MothTime[0] ) ){
        if( moth == Number( MothTime[1] ) ){
            if( day == Number( MothTime[2] ) ){
                return handleComputedTime(AppointTime,[Hours,Fen])
            }else{
                if( ( day - Number( MothTime[2] ) ) < 2 ){
                    return handleComputedTime(AppointTime,[Hours,Fen])
                }else{
                    return "如疑问请拨打55880688"
                }
            }
        }else{
            if( Number( MothTime[1] ) == 12 && moth == 1 ){
                if( Number( MothTime[2] ) < 5 ){
                    return handleComputedTime(AppointTime,[Hours,Fen])
                }else{
                    return "如疑问请拨打55880688"
                }
            }else{
                return "如疑问请拨打55880688"
            }
        }
    }else{
        let Num = year - Number( MothTime[0] )
        if( Num == 1 ){
            if( Number( MothTime[1] ) == 12 && moth == 1 ){
                if( Number( MothTime[2] ) < 5 ){
                    return handleComputedTime(AppointTime,[Hours,Fen])
                }else{
                    return "如疑问请拨打55880688"
                }
            }else{
                return "如疑问请拨打55880688"
            }
        }else{
           return "如疑问请拨打55880688"
        }
    }
}

